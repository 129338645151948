import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TableCell, TableRow, Theme, Typography } from '@mui/material';
import { Cell, Table, flexRender } from '@tanstack/react-table';
import { MutableRefObject } from 'react';

import Icon from '@/components/Icon';
import { LinkBox } from '@/components/LinkOverlay';
import theme from '@/themes';

import { CustomObject } from '../type';

export default function SortableRow({
  id,
  cells,
}: {
  id: string;
  cells: Cell<CustomObject, unknown>[];
  table: Table<CustomObject>;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
    node,
  } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: isDragging ? 'blue.20' : 'white',
  };

  return (
    <TableRow
      ref={setNodeRef}
      {...attributes}
      sx={{ cursor: 'pointer', ...style }}
    >
      {isDragging ? (
        <TableCell colSpan={cells.length + 1}></TableCell>
      ) : (
        <>
          <TableCell
            width="40px"
            sx={{
              color: 'gray.30',
              ':hover': {
                cursor: 'grab',
                color: 'gray.90',
              },
            }}
            ref={setActivatorNodeRef}
            {...listeners}
          >
            <Icon icon="drag-and-drop" size={16} />
          </TableCell>
          {cells.map((cell) => {
            return (
              <LinkBox
                key={cell.id}
                component={TableCell}
                sx={(theme: Theme) => ({
                  ...theme.typography.ellipsis,
                  maxWidth: `${cell.column.getSize()}px`,
                })}
              >
                {flexRender(cell.column.columnDef.cell, {
                  ...cell.getContext(),
                  rowRef: node,
                })}
              </LinkBox>
            );
          })}
        </>
      )}
    </TableRow>
  );
}

export function DraggingRow({
  cells,
  headerRefs,
}: {
  cells: Cell<CustomObject, unknown>[];
  headerRefs: MutableRefObject<HTMLTableCellElement[]>;
}) {
  const style = {
    boxShadow: theme.shadows[4],
    backgroundColor: 'white',
    py: '24px',
    px: '16px',
  };

  return (
    <TableRow style={style}>
      <TableCell
        width="40px"
        sx={{
          cursor: 'grabbing',
        }}
      >
        <Icon
          icon="drag-and-drop"
          sx={{
            color: 'gray.90',
          }}
          size={16}
        />
      </TableCell>

      {cells.map((cell, idx) => {
        return (
          <TableCell
            key={cell.id}
            width={headerRefs.current?.[idx].offsetWidth}
          >
            <Typography
              color="darkBlue.90"
              variant="body2"
              sx={(theme: Theme) => ({
                ...theme.typography.ellipsis,
                maxWidth: `${cell.column.getSize()}px`,
              })}
            >
              {flexRender(cell.column.columnDef.cell, {
                ...cell.getContext(),
              })}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
}
