import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { CustomDialog } from './components/AlertDialog';

export default function AppVersionUpdateDialog() {
  const { t } = useTranslation();
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW: useCallback(
      (swUrl: string, r: ServiceWorkerRegistration | undefined) => {
        console.log('SW Registered: ' + swUrl);
        if (r) {
          setInterval(async () => {
            console.log('SW polling update: ', { ...r });
            if (!(!r.installing && navigator)) return;
            // Don't check for updates if the user is offline
            if ('connection' in navigator && !navigator.onLine) return;
            // Check if service worker exists
            const resp = await fetch(swUrl, {
              cache: 'no-store',
              headers: {
                cache: 'no-store',
                'cache-control': 'no-cache',
              },
            });

            if (resp?.status === 200) {
              await r.update();
            }
            // Check for updates every 15 minutes
          }, 15 * 60 * 1000);
        }
      },
      [],
    ),
    onRegisterError(error) {
      console.error('SW registration error', error);
    },
  });

  async function handleReload() {
    try {
      updateServiceWorker(true);
      setNeedRefresh(false);
    } catch {
      console.error('Failed to update service worker');
    }
  }

  return (
    <CustomDialog
      hideIcon
      open={needRefresh}
      onClose={() => setNeedRefresh(false)}
      renderDialogActions={() => (
        <Button variant="contained" color="primary" onClick={handleReload}>
          {t('app-update.reload-button', {
            defaultValue: 'Reload',
          })}
        </Button>
      )}
      title={t('app-update.title', {
        defaultValue: 'New App Version Available',
      })}
      description={t('app-update.description', {
        defaultValue:
          'A new version of the app is available. Please reload the page to get the latest version.',
      })}
    />
  );
}
