import { Button, ListItemText, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { colors } from '@/themes';

import Icon from '../Icon';
import { ListItemButton, MenuList } from './styled';

export default function SwitchLanguagePanel({
  goBack,
}: {
  goBack: () => void;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  function switchLanguage(lang: string) {
    i18n.changeLanguage(lang);
    navigate({
      ...location,
      pathname: `/${lang}/${location.pathname.split('/').slice(2).join('/')}`,
    });
  }

  return (
    <>
      <Stack
        component="li"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p="8px"
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <Stack direction="row" alignItems="center">
          <Button
            variant="text"
            startIcon={<Icon icon="chevron-left" size={16} />}
            onClick={goBack}
            sx={{ px: '12px' }}
          >
            <Typography variant="button1">{t('switch-language')}</Typography>
          </Button>
        </Stack>
      </Stack>
      <MenuList>
        {[
          {
            lang: 'en',
            label: t('language.en'),
          },
          {
            lang: 'zh-HK',
            label: t('language.zh-HK'),
          },
          {
            lang: 'zh-CN',
            label: t('language.zh-CN'),
          },
          {
            lang: 'id',
            label: t('language.id'),
          },
          {
            lang: 'pt-BR',
            label: t('language.pt-BR'),
          },
        ].map((lang) => (
          <ListItemButton
            key={lang.lang}
            onClick={() => switchLanguage(lang.lang)}
          >
            <ListItemText
              primary={<Typography variant="menu1">{lang.label}</Typography>}
            />
            {i18n.language === lang.lang && (
              <Icon
                icon="check-circle"
                size={20}
                sx={{ color: colors.blue90 }}
              />
            )}
          </ListItemButton>
        ))}
      </MenuList>
    </>
  );
}
