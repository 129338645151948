import { Container } from 'inversify';

import { LanguageService } from './language.service';

function loadDeps(container: Container) {
  container
    .bind<LanguageService>(LanguageService)
    .to(LanguageService)
    .inSingletonScope();
}

export const Languages = {
  loadDeps,
};
