import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { CssBaseline, GlobalStyles } from '@mui/material';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isAxiosError } from 'axios';
import { Provider, useInjection } from 'inversify-react';
import React from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import { AdapterDayjs } from '@/components/DatePicker/AdapterDayjs';
import LocalizationProvider from '@/components/DatePicker/LocalisationProvider';
import V2LocalizationProvider from '@/components/date-time-pickers/LocalizationProvider';
import { useLocaleSetup } from '@/hooks/useLocaleSetup';
import getContainer from '@/inversify.config';
import { AuthService } from '@/services/auth.service';

import GlobalErrorBoundary from './GlobalErrorBoundary';
import RouteWithHelmet from './RouteWithHelmet';
import Toaster from './Toaster';
import { ROUTES } from './constants/navigation';
import { GlobalDialogs } from './dialogs';
import theme from './themes';

function Auth0ProviderWithRedirect({
  children,
}: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const screenHint = searchParams.get('screen_hint');

  return (
    <Auth0Provider
      useRefreshTokens
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
        screen_hint: screenHint || undefined, // determine login or signup widget to show after redirection
      }}
      onRedirectCallback={(appState) =>
        navigate(appState?.returnTo || ROUTES.inbox)
      }
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}

const RXJSAuth0ProviderWithAuth = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    user,
    getIdTokenClaims,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const authService = useInjection(AuthService);

  authService.setupGetAccessTokenSilently(getAccessTokenSilently);
  authService.setupUser(user);
  authService.setupGetIdTokenClaims(getIdTokenClaims);
  authService.setupLoginWithRedirect(loginWithRedirect);
  authService.setupLogout(logout);

  // IsAuthenticated must be the last one
  authService.setupIsAuthenticated(isAuthenticated);

  return <>{children}</>;
};

const MAX_RETRIES = 3;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      staleTime: import.meta.env.VITE_STALE_TIME
        ? Number(import.meta.env.VITE_STALE_TIME)
        : 2 * 60 * 1000,
      cacheTime: import.meta.env.VITE_CACHE_TIME
        ? Number(import.meta.env.VITE_CACHE_TIME)
        : 5 * 60 * 1000,
      retry: (failureCount, error: any) => {
        // Don't retry if the error is a 403 access denied error for IP whitelist
        // AccessDeniedError assigns axios error to `cause`
        if (
          isAxiosError(error.cause) &&
          error.cause?.response?.status === 403
        ) {
          return false;
        }
        if (failureCount > MAX_RETRIES) {
          return false;
        }
        return true;
      },
    },
  },
});

function App() {
  useLocaleSetup();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          html: {
            height: '100%',
            overflow: 'hidden',
          },
          body: {
            height: '100%',
            overflow: 'hidden',
          },
          'body.beamerAnnouncementBarTopActive': {
            paddingTop: '0px !important',
          },
        }}
      />
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <GlobalErrorBoundary>
          <Auth0ProviderWithRedirect>
            <Provider container={() => getContainer()}>
              <RXJSAuth0ProviderWithAuth>
                <V2LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Toaster />
                    <RouteWithHelmet>
                      <Outlet />
                      <GlobalDialogs />
                    </RouteWithHelmet>
                  </LocalizationProvider>
                </V2LocalizationProvider>
              </RXJSAuth0ProviderWithAuth>
            </Provider>
          </Auth0ProviderWithRedirect>
          <ReactQueryDevtools initialIsOpen={false} />
        </GlobalErrorBoundary>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
