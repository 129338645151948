import { captureException, captureMessage, Scope } from '@sentry/react';
import { inject, injectable } from 'inversify';
import { combineLatest, startWith } from 'rxjs';

import { Staff } from '@/services/companies/company.service';
import { CACHE_REFRESHING_BEHAVIOUR } from '@/services/rxjs-utils/rxjs-utils';
import { Company, UserService } from '@/services/user.service';

@injectable()
export class LogService {
  private myStaff: Staff | null = null;
  private myCompany: Company | null = null;

  constructor(@inject(UserService) private userService: UserService) {
    combineLatest({
      myStaff: this.userService
        .getMyStaff$(CACHE_REFRESHING_BEHAVIOUR.ALWAYS_REFRESH_CLIENT)
        .pipe(startWith(null)),
      myCompany: this.userService
        .getMyCompany$(CACHE_REFRESHING_BEHAVIOUR.ALWAYS_REFRESH_CLIENT)
        .pipe(startWith(null)),
    }).subscribe(({ myStaff, myCompany }) => {
      this.myStaff = myStaff;
      this.myCompany = myCompany;
    });
  }

  public log(message: string, ...data: any[]) {
    try {
      console.log(message, ...data);
    } catch (e) {
      console.error('Unable to log the data.');
      console.error(message);
    }

    try {
      const scope = new Scope();

      scope.setUser({
        id: this.myStaff?.id,
        email: this.myStaff?.email,
        staffId: this.myStaff?.staffId,
        companyId: this.myCompany?.id,
      });

      scope.setLevel('info');

      captureMessage(message, scope);
    } catch (e) {
      console.error('Unable to capture the log.');
      console.error(message);
    }
  }

  public error(message: string | any, ...data: any[]) {
    try {
      console.error(message, ...data);
    } catch (e) {
      console.error('Unable to log the data.');
      console.error(message);
    }

    try {
      const scope = new Scope();

      scope.setUser({
        id: this.myStaff?.id,
        email: this.myStaff?.email,
        staffId: this.myStaff?.staffId,
        companyId: this.myCompany?.id,
      });

      scope.setLevel('error');

      captureException(message, scope);
    } catch (e) {
      console.error('Unable to capture the error log.');
      console.error(message);
    }
  }
}
