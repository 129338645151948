import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import { useInjection } from 'inversify-react';
import { useObservableEagerState } from 'observable-hooks';
import { useMemo } from 'react';
import { startWith } from 'rxjs';

import { useGetStaffById } from '@/api/company';
import { TenantService } from '@/services/tenants/tenant.service';

const tryParseJSON = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

type DiveUser = {
  company_id: string;
  expire_at: Date;
  staff_id: number;
  user_id: string;
};

type ConnectionStrategy =
  | 'auth0'
  | 'google-oauth2'
  | 'apple'
  | 'adfs'
  | 'openid'
  | undefined;

export type MyProfile = ReturnType<typeof useMyProfile>;

const userKey = 'https://app.sleekflow.io/user_id';

export function useMyProfile(props?: { suspense?: boolean }) {
  const { user } = useAuth0();
  const tenantService = useInjection<TenantService>(TenantService);

  const userWorkspaces$ = useMemo(() => {
    return tenantService.getUserWorkspaces$().pipe(startWith([]));
  }, [tenantService]);
  const userWorkspaces = useObservableEagerState(userWorkspaces$);

  const stringifiedDiveUserInfo =
    user?.['https://app.sleekflow.io/login_as_user'];
  const diveUserInfo: DiveUser | null = stringifiedDiveUserInfo
    ? tryParseJSON(stringifiedDiveUserInfo)
    : null;
  const diveTimeRemaining = diveUserInfo?.expire_at
    ? dayjs(diveUserInfo?.expire_at).diff(dayjs(), 'seconds')
    : 0;

  const userId =
    diveTimeRemaining > 0 && diveUserInfo
      ? diveUserInfo.user_id
      : user?.[userKey] || '';

  const staffInfo = useGetStaffById({
    userId,
    suspense: props?.suspense,
    select: (data) => {
      if (data.length > 0) {
        const staff = data[0];

        // BE should not return integer type but they did
        const associatedTeams = staff.associatedTeams.map((team) => ({
          ...team,
          id: String(team.id),
        }));

        const result = {
          ...staff,
          auth0User: user,
          connectionStrategy: user?.[
            'https://app.sleekflow.io/connection_strategy'
          ] as ConnectionStrategy,
          userWorkspaces: userWorkspaces,
          associatedTeams,
        };
        const resultCast = result as Omit<typeof result, 'name'> & {
          name?: string;
          connectionStrategy: ConnectionStrategy;
        };
        //Suggest using 'displayName' to maintain the consistency, delete 'name' to avoid confusion
        delete resultCast.name;

        return resultCast as Omit<typeof result, 'name'>;
      }
      throw new Error('Cannot get staff info');
    },
    staleTime: Infinity,
  });

  return staffInfo;
}
