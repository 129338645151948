import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import AppVersionUpdateDialog from '@/AppVersionUpdateDialog';
import { useCompany } from '@/api/company';
import AppLoading from '@/components/AppLoading';
import Navbar from '@/components/Navbar';
import PushNotification from '@/components/PushNotification';
import useAIStatusLongPolling from '@/hooks/useAIStatusLongPolling';
import { useMyProfile } from '@/hooks/useMyProfile';
import {
  fromApiBillRecordsToActiveBillRecord,
  transformPlanDetails,
} from '@/utils/billing';
import { getAuthType, getDeviceType, getLanguage } from '@/utils/mixpanelLibs';

export default function AuthenticatedLayout() {
  useAnalytics();
  useAIStatusLongPolling();

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=0.1" />
      </Helmet>
      <Box display="flex">
        <Navbar />
        <PushNotification />
        {import.meta.env.PROD && <AppVersionUpdateDialog />}
        <Box
          component="main"
          flex={1}
          height="100svh"
          width="100vw"
          overflow="hidden"
        >
          <Suspense fallback={<AppLoading withoutNavbar />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </>
  );
}

function useAnalytics() {
  const location = useLocation();
  const staffInfo = useMyProfile();
  const {
    i18n: { language },
  } = useTranslation();
  const company = useCompany({
    select: ({ id, companyName, billRecords, createdAt }) => {
      const activeBillRecord =
        fromApiBillRecordsToActiveBillRecord(billRecords);
      const planName = transformPlanDetails(
        activeBillRecord?.subscriptionPlan?.id,
      ).planName;

      return {
        id,
        companyName,
        subscriptionPlan: planName ? titleCase(planName) : 'Unknown',
        createdAt,
      };
    },
  });

  useEffect(() => {
    if (!staffInfo.data || !company.data) return;

    const { firstName, lastName, displayName } = staffInfo.data.userInfo;

    const username =
      firstName && lastName ? `${firstName} ${lastName}` : displayName;

    // Sentry
    Sentry.setUser({
      id: staffInfo.data.userInfo.id,
      companyId: company.data.id,
      userId: staffInfo.data.userInfo.id,
      username,
      email: staffInfo.data.userInfo.email,
      staffId: staffInfo.data.staffId,
      associatedTeams: JSON.stringify(staffInfo.data.associatedTeams),
      userRole: staffInfo.data.userInfo.userRole,
      createdAt: staffInfo.data.userInfo.createdAt,
      roleType: staffInfo.data.roleType,
      locale: staffInfo.data.locale,
      timeZoneInfo: JSON.stringify(staffInfo.data.timeZoneInfo),
      status: staffInfo.data.status,
    });

    // Mixpanel
    mixpanel.identify(staffInfo.data.userInfo.id);
    mixpanel.register({
      $email: staffInfo.data.userInfo.email,
      Role: staffInfo.data.userInfo.userRole,
      'Subscription Plan': company.data.subscriptionPlan,
      Platform: 'Web v2',
      language: getLanguage(language),
      'Device Type': getDeviceType(),
      'Web App Version': import.meta.env.VITE_WEB_APP_VERSION,
    });
    mixpanel.people.set({
      'Company ID': company.data.id,
      'Company Name': company.data.companyName,
      $email: staffInfo.data.userInfo.email,
      $name: username,
      Username: username,
      'User Role': staffInfo.data.roleType,
      'Subscription Plan': company.data.subscriptionPlan,
      'Authentication Method': getAuthType(
        staffInfo.data.auth0User?.[
          'https://app.sleekflow.io/connection_strategy'
        ],
      ),
      'Company Creation Time': company.data.createdAt,
    });
    mixpanel.track('Session Initialized');

    return () => {
      mixpanel.reset();
    };
  }, [staffInfo.data, company.data, language]);

  // Track page view since Mixpanel doesn't track it automatically on SPA
  useEffect(() => {
    if (!staffInfo.data || !company.data) return;

    const { email, userRole } = staffInfo.data.userInfo;
    // Wait and add user info manually to skip the initial page view tracking that has no user info
    mixpanel.track_pageview({
      $email: email,
      Role: userRole,
      'Subscription Plan': company.data.subscriptionPlan,
      Platform: 'Web v2',
    });
  }, [location.pathname, staffInfo.data, company.data]);
}

function titleCase(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
