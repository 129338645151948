// Credits: https://stackoverflow.com/a/18650828/6820538
import i18n from '@/i18n';

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat(`${bytes / Math.pow(k, i)}`).toFixed(dm)} ${sizes[i]}`;
};

export const formatCurrency = (
  amount: number,
  currency: string | false | null = 'hkd',
  maximumFractionDigits?: number,
) => {
  let opts: Record<string, any> = {
    minimumFractionDigits: 2,
  };

  if (currency !== false) {
    opts = {
      style: 'currency',
      currency,
    };
  }

  if (maximumFractionDigits !== undefined) {
    opts.maximumFractionDigits = maximumFractionDigits;
  }

  return new Intl.NumberFormat(i18n.language, opts).format(amount);
};

export type DigitFormat = `${number}.${number}-${number}`;

export function formatNumber(
  amount: number | undefined,
  options: { format: DigitFormat } & Omit<
    Intl.NumberFormatOptions,
    'minimumIntegerDigits' | 'minimumFractionDigits' | 'maximumFractionDigits'
  > = { format: '1.0-0' },
): string {
  if (typeof amount !== 'number') {
    return '-';
  }

  const { format, ...rest } = options;
  const match = format.match(/^(\d+)\.(\d+)-(\d+)$/);

  let formatOptions: Intl.NumberFormatOptions = { ...rest };
  if (match) {
    formatOptions = {
      ...formatOptions,
      minimumIntegerDigits: parseInt(match[1]),
      minimumFractionDigits: parseInt(match[2]),
      maximumFractionDigits: parseInt(match[3]),
    };
  }

  const isChinese = i18n.language.includes('zh');

  return new Intl.NumberFormat(
    // Fallback to EN for any ZH locales to prevent showing chinese characters
    isChinese ? 'en-US' : i18n.language,
    formatOptions,
  ).format(amount);
}
