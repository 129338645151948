import { injectable } from 'inversify';
import { of } from 'rxjs';

@injectable()
export class LanguageService {
  public getLanguages$() {
    return of([
      { id: 1, name: 'English' },
      { id: 2, name: 'Spanish' },
      { id: 3, name: 'French' },
    ]);
  }

  public getLanguage$() {
    return of([{ id: 1, name: 'English' }]);
  }
}
