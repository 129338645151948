import { useGetCrmHubConfigQuery } from '@/api/crmHub';

export default function useCustomObjectDataAccessControl() {
  const { data: canAccessCustomObject } = useGetCrmHubConfigQuery({
    select: (data) =>
      data.feature_accessibility_settings.can_access_custom_object,
  });

  return {
    canAccessCustomObjectData: canAccessCustomObject ?? false,
  };
}
