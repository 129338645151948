import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useAxios } from './axiosClient';

type CrmHubConfigResponse = {
  usage_limit: CrmHubConfigUsageLimit;
  sleekflow_company_id: string;
  created_by: {
    sleekflow_staff_id: string;
  };
  updated_by: {
    sleekflow_staff_id: string;
  };
  created_at: string;
  updated_at: string;
  id: string;
  feature_accessibility_settings: {
    can_access_custom_object: boolean;
    can_access_custom_object_flow_builder_components: boolean;
  };
};

type CrmHubConfigUsageLimit = {
  custom_object_maximum_schema_num: number;
  custom_object_maximum_property_num_per_schema: number;
  custom_object_maximum_schemaful_object_num_per_schema: number;
  custom_object_maximum_schemaful_object_num_per_company: number;
};

export type CrmProviderRequestPayload = {
  providerName: string;
  providerConnectionId: string;
  entityTypeName: string;
};

export const crmHubKeys = createQueryKeys('crm', {
  getCrmHubConfig: null,
  list(provider: string) {
    return ['list', provider];
  },
  getProviderTypeFields: ({
    providerName,
    providerConnectionId,
    entityTypeName,
  }: CrmProviderRequestPayload) => ({
    providerName,
    providerConnectionId,
    entityTypeName,
  }),
});

export const useGetCrmHubConfigQuery = <T = CrmHubConfigResponse>({
  select,
}: {
  select?: (data: CrmHubConfigResponse) => T;
} = {}) => {
  const axiosClient = useAxios();
  const url = '/CrmHub/CrmHubConfigs/GetCrmHubConfig';

  return useQuery<any, AxiosResponse<T>>({
    queryKey: crmHubKeys.getCrmHubConfig,
    queryFn: async () => {
      const result = await axiosClient.post(url);
      return result.data;
    },
    select,
  });
};

type ItegrationNormalized = {
  id: string;
  name: string;
  environment: 'production' | 'sandbox';
  organization_id: string;
  is_active: boolean;
  sleekflow_company_id: string;
};

export type CrmIntegrationsResponse = {
  connections: ItegrationNormalized[];
};

export function useCrmHubIntegrations<TData>(
  provider: string,
  props: {
    select?: (data: CrmIntegrationsResponse) => TData;
    suspense?: boolean;
  },
) {
  const axiosClient = useAxios();

  return useQuery(
    crmHubKeys.list(provider),
    async ({ signal }) => {
      const response = await axiosClient.post(
        '/CrmHub/GetProviderConnections',
        { signal, provider_name: provider },
      );
      return response.data;
    },
    {
      select: props.select,
      suspense: props.suspense ?? true,
    },
  );
}
export const SALESFORCE_PROPERTY_FIELD_TYPES = [
  'datetime',
  'boolean',
  'number',
  'string',
  'id',
  'reference',
  'double',
  'url',
  'phone',
  'email',
  'picklist',
] as const;

export type SalesforcePropertyFieldType =
  typeof SALESFORCE_PROPERTY_FIELD_TYPES[number];

type CrmUpdatableField = {
  calculated: boolean;
  createable: boolean;
  custom: boolean;
  encrypted: boolean;
  label: string;
  length: number;
  name: string;
  picklist_values: PickListOption[];
  soap_type: string;
  type: SalesforcePropertyFieldType;
  unique: boolean;
  updateable: boolean;
  mandatory: boolean;
};

export type PickListOption = {
  label: string;
  value: string;
};

type CrmUpdatableFieldsResponse = {
  updatable_fields: CrmUpdatableField[];
  creatable_fields: CrmUpdatableField[];
  viewable_fields: CrmUpdatableField[];
};

export const useGetUpdatableFieldsQuery = <T = CrmUpdatableFieldsResponse>({
  providerName,
  providerConnectionId,
  entityTypeName,
  select,
  enabled,
}: CrmProviderRequestPayload & {
  select?: (data: CrmUpdatableFieldsResponse) => T;
  enabled?: boolean;
}) => {
  const axiosClient = useAxios();
  const url = '/CrmHub/GetProviderTypeFieldsV2';

  return useQuery({
    queryKey: crmHubKeys.getProviderTypeFields({
      providerName,
      providerConnectionId,
      entityTypeName,
    }),
    queryFn: async () => {
      const result = await axiosClient.post<CrmUpdatableFieldsResponse>(url, {
        provider_name: providerName,
        provider_connection_id: providerConnectionId,
        entity_type_name: entityTypeName,
      });
      return result.data;
    },
    select,
    enabled,
  });
};
