import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  SleekflowApisTicketingHubModelGetTicketPrioritiesOutput,
  SleekflowApisTicketingHubModelGetTicketsOutput,
  SleekflowApisTicketingHubModelGetTicketStatusesOutput,
  SleekflowApisTicketingHubModelGetTicketTypesOutput,
  SleekflowApisTicketingHubModelTicketCompanyConfig,
  SleekflowApisTicketingHubModelTicketDto,
} from '@sleekflow/sleekflow-core-typescript-rxjs-apis';
import {
  useInfiniteQuery,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useInjection } from 'inversify-react';
import { useMemo } from 'react';
import { firstValueFrom } from 'rxjs';

import {
  TicketCountResult,
  TicketingService,
} from '@/services/ticketing/ticketing.service';

export interface DownloadBlobUrlsArgs {
  blobNames: string[];
  blobType: string;
}

interface Filter {
  field_name: string;
  operator: string;
  value: string;
}

export interface TicketListParams {
  priority?: string[];
  status?: (string | Filter)[];
  assignee?: string[];
  channel?: {
    channel_type: string;
    channel_identity_id: string;
  }[];
  type?: string[];
  contact?: string[];

  limit?: number;
  sort?: {
    field_name: string;
    direction: 'asc' | 'desc';
    is_case_sensitive?: boolean;
  };
  search?: string | null;
  deleted?: boolean;
  group_by?: string;
}

export const ticketingKeys = createQueryKeys('ticketing', {
  config: null,
  ticketTypes: null,
  ticketPriorities: null,
  ticketStatuses: null,
  uploadBlobs: null,
  getDownloadBlobUrls: ({ blobNames, blobType }: DownloadBlobUrlsArgs) => ({
    blobNames,
    blobType,
  }),
  list: (filters: TicketListParams) => [{ filters }],
  listTotalCount: (filters: TicketListParams) => [{ filters }],
  detail: (ticketId: string) => [ticketId],
});

export const useTicketingConfig = (
  options: UseQueryOptions<
    SleekflowApisTicketingHubModelTicketCompanyConfig | undefined
  > = {},
) => {
  const ticketingService = useInjection(TicketingService);

  return useQuery({
    queryKey: ticketingKeys.config,
    queryFn: () =>
      firstValueFrom(ticketingService.getCompanyTicketingConfig$()),
    staleTime: Infinity,
    useErrorBoundary: false, // Failing to get ticketing config should not break the app
    ...options,
    // Disable ticketing in production
    ...(import.meta.env.VITE_USER_NODE_ENV === 'production' && {
      enabled: false,
      initialData: {
        is_ticket_enabled: false,
      },
    }),
  });
};

export const useTicketingPriorities = ({
  enabled,
  ...options
}: UseQueryOptions<
  SleekflowApisTicketingHubModelGetTicketPrioritiesOutput | undefined,
  unknown,
  SleekflowApisTicketingHubModelGetTicketPrioritiesOutput['records']
> = {}) => {
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  return useQuery({
    queryKey: ticketingKeys.ticketPriorities,
    queryFn: () => firstValueFrom(ticketingService.getTicketPriorities$()),
    select: (data) => data?.records,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: ticketingConfig?.is_ticket_enabled && enabled,
    ...options,
  });
};

export const useTicketingTypes = ({
  enabled,
  ...options
}: UseQueryOptions<
  SleekflowApisTicketingHubModelGetTicketTypesOutput | undefined,
  unknown,
  SleekflowApisTicketingHubModelGetTicketTypesOutput['records']
> = {}) => {
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  return useQuery({
    queryKey: ticketingKeys.ticketTypes,
    queryFn: () => firstValueFrom(ticketingService.getTicketTypes$()),
    select: (data) => data?.records,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: ticketingConfig?.is_ticket_enabled && enabled,
    ...options,
  });
};

export const useTicketingStatuses = ({
  enabled,
  ...options
}: UseQueryOptions<
  SleekflowApisTicketingHubModelGetTicketStatusesOutput | undefined,
  unknown,
  SleekflowApisTicketingHubModelGetTicketStatusesOutput['records']
> = {}) => {
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ticketingKeys.ticketStatuses,
    queryFn: () => firstValueFrom(ticketingService.getTicketStatuses$()),
    select: (data) => data?.records,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: ticketingConfig?.is_ticket_enabled && enabled,
    ...options,
  });

  const DefaultTicketStatus = useMemo(
    () =>
      (data
        ?.filter((status) => status.is_default && status.internal_value)
        .reduce(
          (acc, status) => ({
            ...acc,
            [(status.internal_value as string).toUpperCase()]: status.id!,
          }),
          {},
        ) ?? {}) as Record<string, string | undefined>,
    [data],
  );

  return {
    data,
    isLoading,
    DefaultTicketStatus,
    refetch,
    isFetching,
  };
};

interface UseTicketListParams
  extends UseQueryOptions<
    SleekflowApisTicketingHubModelGetTicketsOutput | undefined
  > {
  params?: TicketListParams;
}

const buildTicketListFilterGroup = (params: TicketListParams) => {
  const filterGroups = [
    {
      filters: [
        {
          field_name: 'record_statuses',
          operator: 'array_contains',
          value: (params.deleted ? 'Deleted' : 'Active') as string | number,
        },
      ],
    },
  ];

  if (params.contact && params.contact.length > 0) {
    filterGroups.push({
      filters: params.contact.map((contactId) => ({
        field_name: 'sleekflow_user_profile_id',
        operator: '=',
        value: contactId,
      })),
    });
  }

  if (params.status && params.status.length > 0) {
    filterGroups.push({
      filters: params.status.map((statusIdOrFilter) =>
        typeof statusIdOrFilter === 'string'
          ? {
              field_name: 'status_id',
              operator: '=',
              value: statusIdOrFilter,
            }
          : statusIdOrFilter,
      ),
    });
  }

  if (params.search) {
    filterGroups.push({
      filters: [
        {
          field_name: 'external_id',
          operator: '=',
          value: Number(params.search.replace('#', '')),
        },
        {
          field_name: 'title',
          operator: 'contains',
          value: params.search,
        },
      ],
    });
  }

  if (params.assignee && params.assignee.length > 0) {
    filterGroups.push({
      filters: params.assignee.map((assigneeId) => ({
        field_name: 'assignee_id',
        operator: '=',
        value: assigneeId,
      })),
    });
  }

  if (params.priority && params.priority.length > 0) {
    filterGroups.push({
      filters: params.priority.map((priorityId) => ({
        field_name: 'priority_id',
        operator: '=',
        value: priorityId,
      })),
    });
  }

  if (params.type && params.type.length > 0) {
    filterGroups.push({
      filters: params.type.map((typeId) => ({
        field_name: 'type_id',
        operator: '=',
        value: typeId,
      })),
    });
  }

  if (params.channel && params.channel.length > 0) {
    filterGroups.push(
      {
        filters: params.channel.map(({ channel_type }) => ({
          field_name: 'channel.channel_type',
          operator: '=',
          value: channel_type,
        })),
      },
      {
        filters: params.channel.map(({ channel_identity_id }) => ({
          field_name: 'channel.channel_identity_id',
          operator: '=',
          value: channel_identity_id,
        })),
      },
    );
  }

  return filterGroups;
};

export const useTicketList = ({
  enabled,
  params = {},
}: UseTicketListParams = {}) => {
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  return useInfiniteQuery({
    queryKey: ticketingKeys.list(params),
    queryFn: ({ pageParam }) =>
      firstValueFrom(
        ticketingService.getTickets$({
          continuation_token: pageParam,
          filter_groups: buildTicketListFilterGroup(params),
          limit: params.limit,
          sort: params.sort || {
            direction: 'desc',
            field_name: 'created_at',
            is_case_sensitive: false,
          },
        }),
      ),
    staleTime: 0,
    keepPreviousData: true,
    getNextPageParam: (
      lastPage: SleekflowApisTicketingHubModelGetTicketsOutput | undefined, // TODO: remove manual type on TypesScript v4.9+
    ) => lastPage?.continuation_token,
    enabled: ticketingConfig?.is_ticket_enabled && enabled,
  });
};

interface UseTickeCountParams<T>
  extends UseQueryOptions<TicketCountResult[], unknown, T> {
  params?: TicketListParams;
  select?: (data: TicketCountResult[]) => T;
}

export const useTicketCount = <T = TicketCountResult[]>({
  params = {},
  enabled,
  ...options
}: UseTickeCountParams<T> = {}) => {
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  return useQuery({
    queryKey: ticketingKeys.listTotalCount(params),
    queryFn: () =>
      firstValueFrom(
        ticketingService.getTicketCount$({
          filter_groups: buildTicketListFilterGroup(params),
          group_bys: params.group_by ? [{ field_name: params.group_by }] : [],
        }),
      ),
    staleTime: 0,
    useErrorBoundary: false, // Ticket count is not essential, no need to throw
    enabled: ticketingConfig?.is_ticket_enabled && enabled,
    ...options,
  });
};

interface UseTicketOpions
  extends UseQueryOptions<SleekflowApisTicketingHubModelTicketDto | undefined> {
  ticketId?: string | null;
}

export const useTicket = ({
  ticketId,
  enabled,
  ...options
}: UseTicketOpions = {}) => {
  const ticketingService = useInjection(TicketingService);
  const { data: ticketingConfig } = useTicketingConfig();

  return useQuery({
    queryKey: ticketingKeys.detail(ticketId!),
    queryFn: () => firstValueFrom(ticketingService.getTicket$(ticketId!)),
    staleTime: 0,
    enabled: Boolean(ticketId) && ticketingConfig?.is_ticket_enabled && enabled,
    ...options,
  });
};
