import { DndContext, DragOverlay, closestCenter } from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import { useRef } from 'react';

import { useCustomObjectListInfiniteQuery } from '@/api/customObject';
import InfiniteScroll from '@/components/InfiniteScroll';
import TableFetchingOverlay from '@/pages/Contacts/shared/TableFetchingOverlay';

import { fromApiGetCustomObject } from '../adapters';
import useCustomObjectTable from '../hooks/useCustomObjectTable';
import useCustomObjectTableDnd from '../hooks/useCustomObjectTableDnd';
import { CustomObject } from '../type';
import CustomObjectTableSkeleton from './CustomObjectSkeleton';
import SortableRow, { DraggingRow } from './SortableTableRow';

export const OBJECT_LOADING_LIMIT = 25;

export default function CustomObjectListTable() {
  const customObjectList = useCustomObjectListInfiniteQuery<CustomObject>({
    limit: OBJECT_LOADING_LIMIT,
    select: (data) => {
      return {
        ...data,
        pages: data.pages.flatMap((page) =>
          page.schemas.map((schema) => fromApiGetCustomObject(schema)),
        ),
      };
    },
  });

  const {
    handleDragCancel,
    handleDragEnd,
    handleDragStart,
    activeId,
    sensors,
    isReordering,
  } = useCustomObjectTableDnd();

  const viewportRef = useRef<HTMLDivElement>(null);
  const headerRefs = useRef<HTMLTableCellElement[]>([]);
  const table = useCustomObjectTable(customObjectList.data?.pages || []);
  const tableHeaders = table.getHeaderGroups();
  const tableRows = table.getRowModel().rows;

  if (customObjectList.isLoading) {
    return <CustomObjectTableSkeleton />;
  }

  return (
    <Box ref={viewportRef}>
      <InfiniteScroll
        loading={customObjectList.isFetchingNextPage}
        hasNext={customObjectList.hasNextPage}
        onNext={customObjectList.fetchNextPage}
        height="calc(100svh - 220px)"
      >
        <TableFetchingOverlay
          isFetching={isReordering}
          containerRef={viewportRef}
          sx={{ zIndex: 1000 }}
        />
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          modifiers={[restrictToVerticalAxis]}
        >
          <Table>
            <TableHead sx={{ position: 'sticky', top: 0, zIndex: 50 }}>
              <TableRow>
                <TableCell />
                {tableHeaders.map((headerGroup) => {
                  return headerGroup.headers.map((header, idx) => {
                    return (
                      <TableCell
                        key={header.id}
                        ref={(el: HTMLTableCellElement) =>
                          (headerRefs.current[idx] = el)
                        }
                        sx={{
                          width:
                            header.id === 'name' || header.id === 'displayedId'
                              ? '30%'
                              : 'auto',
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </TableCell>
                    );
                  });
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <SortableContext
                items={tableRows}
                strategy={verticalListSortingStrategy}
              >
                {tableRows.map((row) => {
                  const visibleCells = row.getVisibleCells();
                  return (
                    <SortableRow
                      key={row.id}
                      cells={visibleCells}
                      id={row.id}
                      table={table}
                    />
                  );
                })}
              </SortableContext>
              <DragOverlay
                wrapperElement="tr"
                modifiers={[
                  restrictToVerticalAxis,
                  restrictToFirstScrollableAncestor,
                ]}
              >
                {activeId ? (
                  <DraggingRow
                    cells={table
                      .getRowModel()
                      .rowsById[activeId].getVisibleCells()}
                    headerRefs={headerRefs}
                  />
                ) : null}
              </DragOverlay>
            </TableBody>
          </Table>
        </DndContext>
      </InfiniteScroll>
    </Box>
  );
}
