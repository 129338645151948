import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';

import { useAxios } from '@/api/axiosClient';

import type {
  Assignee,
  ChannelType,
  Conversation,
  ConversationMessage,
  ConversationStatus,
  Team,
} from './types';

export type ConversationMessageFilters = {
  conversationId?: string;
  offset?: number;
  limit?: number;
  order?: 'asc' | 'desc';
  afterTimestamp?: number;
  beforeTimestamp?: number;
  channel?: ChannelType;
  channelIds?: string | number;
  IsFromUser?: boolean;
  IsFromImport?: boolean;
  isGetFileOnly?: boolean;
} & { timestamp?: number };

interface ConversationSummaryFilters {
  status?: ConversationStatus;
  afterUpdatedAt?: string;
  afterModifiedAt?: string;
  channelIds?: Record<string, string>[];
  tags?: string;
  teamId?: string;
  isTeamUnassigned?: boolean;
  isAssigned?: boolean;
  isUnread?: boolean;
  channel?: string[];
  behaviourVersion?: '1' | '2';
}

export interface ConversationAssignedToFilters extends ConversationFilters {
  offset?: number;
  limit?: number;
  orderBy?: 'asc' | 'desc';
}

export interface ConversationFilters extends ConversationSummaryFilters {
  assignedTo:
    | 'all'
    | 'unassigned'
    | 'mentioned'
    | 'team'
    | Omit<
        string,
        'all' | 'unassigned' | 'mentioned' | 'collaborator' | 'team'
      >;
}

export interface ConversationsParams {
  status: string;
  assignedTo?: string;
  isTeamUnassigned?: boolean;
  teamId?: string;
  isAssigned?: boolean;
  orderBy?: 'asc' | 'desc';
  isStaffFetchingAll: boolean;
}

export const conversationKeys = createQueryKeys('conversation', {
  conversationMessageByMessageUniqueID: (params: { messageID: string }) => [
    { ...params },
  ],
  addLabels: () => ['conversations', 'setHashtagLabels'],
  conversationWithFiltering: (params: ConversationAssignedToFilters) => [
    { ...params },
  ],
  threadCountSummary: (params: ConversationFilters) => [{ ...params }],
  message: (
    params: ConversationMessageFilters,
    options: { type?: 'query' | 'infinite' } = {},
  ) => [params, options],
  unreadSummary: null,
  getConversationById: ({ conversationId }: { conversationId: string }) => [
    { conversationId },
  ],
  getUrlByFileId: ({ fileId }: { fileId: string }) => [{ fileId }],
  assigneeMenuItems: ({ conversationId }: { conversationId?: string }) => [
    { conversationId },
  ],
  conversations: (params: ConversationsParams) => [{ ...params }],
  summary: (params) => [{ ...params }],
  conversationTopControls: ({
    threadId,
    channelId,
  }: {
    threadId: string;
    channelId?: string | number;
  }) => [threadId, { channelId }],
  conversationSearch: (params) => [{ ...params }],
  conversationSearchCount: (params) => [{ ...params }],
});

export function useConversationMessage<T = ConversationMessage[]>({
  select,
  params: {
    conversationId,
    offset = 0,
    limit = 10,
    order = 'desc',
    ...restParams
  },
  enabled,
  suspense,
}: {
  select?: (data: ConversationMessage[]) => T;
  suspense?: boolean;
  params: ConversationMessageFilters;
  enabled?: boolean;
}): UseQueryResult<T, unknown> {
  const url = `/Conversation/Message/${conversationId}`;
  const axiosClient = useAxios();
  return useQuery({
    queryKey: conversationKeys.message(
      {
        conversationId: conversationId,
        offset,
        limit,
        order,
        ...restParams,
      },
      {
        type: 'query',
      },
    ),
    queryFn: async () => {
      const response = await axiosClient.get<ConversationMessage[]>(url, {
        params: { offset, limit, order, ...restParams },
      });

      return response.data;
    },
    suspense,
    select,
    enabled,
    meta: {
      url,
      description: 'Get all conversation`s msg',
    },
  });
}

interface AssignConversationMutationResponse {
  conversationId: string;
  companyId: string;
  status: string;
  assignee: Assignee;
  assignedTeam: Team;
  additionalAssignees: { assignee: Assignee }[];
  updatedTime: string;
}

interface ConversationCollaboratorMutationArgs {
  additionalAssigneeIds: string[];
}

export function useConversationCollaboratorMutation({
  conversationId,
}: {
  conversationId: string;
}) {
  const url = `/v2/conversation/collaborator/${conversationId}`;
  const axiosClient = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      additionalAssigneeIds,
    }: ConversationCollaboratorMutationArgs) => {
      const response =
        await axiosClient.post<AssignConversationMutationResponse>(url, {
          additionalAssigneeIds,
        });
      return response.data;
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries(conversationKeys.threadCountSummary._def);
      // TODO: Invalidation won't help at time since BE has cache in place
      const conversationQueryKey = conversationKeys.getConversationById({
        conversationId,
      });
      await queryClient.cancelQueries(conversationQueryKey);
      queryClient.setQueryData<Conversation>(conversationQueryKey, (prev) => {
        if (prev) {
          return {
            ...prev,
            additionalAssignees: data.additionalAssignees,
          };
        }
      });
    },
  });
}

export interface ConversationTagsWithIdMutationArgs
  extends ConversationTagsMutationArgs {
  id: string;
}

export interface ConversationTagsMutationArgs {
  hashtag: string;
  hashTagColor: string;
  hashTagType: string;
}
