import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { captureException } from '@sentry/react';
import axios from 'axios';
import { useInjection } from 'inversify-react';
import { useObservableState } from 'observable-hooks';
import { ComponentProps, ComponentType, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useAuth0AccountIsCompanyRegisteredQuery } from '@/api/auth0Account';
import { axiosClient } from '@/api/axiosClient';
import { useGetCurrentIpQuery } from '@/api/common';
import AppLoading from '@/components/AppLoading';
import { AccessDeniedError } from '@/errors/AccessDeniedError';
import { NotFoundError } from '@/errors/NotFoundError';
import { ServerError } from '@/errors/ServerError';
import { TenantService } from '@/services/tenants/tenant.service';
import { generateV1RedirectionLink } from '@/utils/v1-utils';

const withAuth0AccessToken = (Component: ComponentType) => {
  const MyComp = (props: ComponentProps<any>): JSX.Element => {
    const { t } = useTranslation();

    const { data: currentIp } = useGetCurrentIpQuery();
    const { getAccessTokenSilently, logout } = useAuth0();
    axiosClient.interceptors.request.clear();
    axiosClient.interceptors.request.use(async (config) => {
      const accessToken = await getAccessTokenSilently();
      if (accessToken && !config.skipAuth) {
        config.headers.setAuthorization(`Bearer ${accessToken}`);
      }
      return config;
    });
    axiosClient.interceptors.response.clear();
    axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (axios.isAxiosError(error)) {
          // Axios's cancelled error message is just canceled, regardless browser
          if (error.message !== 'canceled') {
            captureException(error);
          }
          if (error.response?.status === 401) {
            logout({
              openUrl() {
                window.location.replace(window.location.origin);
              },
            });
          }
          if (error.response?.status === 404) {
            throw new NotFoundError({
              cause: error,
              message: error.message,
            });
          }

          if (error.response?.status === 403) {
            throw new AccessDeniedError({
              cause: error,
              action: {
                onClick: () => {
                  logout({
                    openUrl() {
                      window.location.replace(window.location.origin);
                    },
                  });
                },
                label: t('access-denied-non-ip-white-list.back-button', {
                  defaultValue: 'Back to Sign In',
                }),
              },
              description: (
                <>
                  {t('access-denied-non-ip-white-list.description', {
                    defaultValue:
                      'Access to SleekFlow from your current IP address ({ip}) is blocked for security reasons. Please contact your workspace admin for assistance.',
                    ip: currentIp?.ipAddress ?? '',
                  })}
                </>
              ),
            });
          }
        }
        throw error;
      },
    );
    return <Component {...props} />;
  };

  MyComp.displayName = 'WithAuth0AccessToken';

  return MyComp;
};

const withWorkspaceLocation = (Component: ComponentType) => {
  const MyComp = (props: ComponentProps<any>) => {
    const tenantService = useInjection<TenantService>(TenantService);

    const userWorkspaces$ = useMemo(() => {
      return tenantService.getUserWorkspaces$();
    }, [tenantService]);
    const userWorkspaces = useObservableState(userWorkspaces$, undefined);

    if (userWorkspaces) {
      const defaultWorkspace = userWorkspaces.find((w) => w.is_default);

      if (!defaultWorkspace) {
        throw new ServerError({
          message: 'cannot find user location workspace',
        });
      }

      axiosClient.interceptors.request.use(async (config) => {
        config.headers.set(
          'X-Sleekflow-Location',
          defaultWorkspace.server_location,
        );
        return config;
      });
    }

    if (userWorkspaces === undefined) {
      return <AppLoading />;
    }

    return <Component {...props} />;
  };

  MyComp.displayName = 'withWorkspaceLocation';

  return MyComp;
};

const withCompanyCheck = (Component: any) => {
  const MyComp = (props: ComponentProps<any>) => {
    const isCompanyRegisteredQuery = useAuth0AccountIsCompanyRegisteredQuery();
    const {
      i18n: { language },
    } = useTranslation();

    if (isCompanyRegisteredQuery.isLoading) {
      return <AppLoading />;
    }

    if (!isCompanyRegisteredQuery.data?.data?.is_company_registered) {
      window.location.replace(
        generateV1RedirectionLink({
          path: '/',
          language,
        }),
      );

      return <AppLoading />;
    }

    return <Component {...props} />;
  };

  MyComp.displayName = 'WithCompanyCheck';

  return MyComp;
};

export default function AuthenticationRequiredLayout() {
  const { i18n } = useTranslation();
  const { error, logout } = useAuth0();

  if (error) {
    logout();
  }

  const Component = withAuthenticationRequired(
    withAuth0AccessToken(withCompanyCheck(withWorkspaceLocation(Outlet))),
    {
      // TODO: update loading UI when mock up is ready
      onRedirecting: () => <AppLoading />,
      loginOptions: {
        authorizationParams: {
          ui_locales: i18n.language === 'zh-HK' ? 'zh-TW' : i18n.language,
        },
      },
    },
  );

  return (
    <Suspense fallback={<AppLoading />}>
      <Component />
    </Suspense>
  );
}
