import { parseISO } from 'date-fns';
import { interfaces } from 'inversify';
import {
  distinctUntilChanged,
  Observable,
  ReplaySubject,
  takeUntil,
} from 'rxjs';

import { RxjsUtils } from '@/services/rxjs-utils/rxjs-utils';

import { ArrayPagedDataSource } from '../data-sources/array-paged-data-source';
import { DisposableDataSource } from '../data-sources/disposable-data-source';
import { DataSourceListRange } from '../data-sources/models/data-source-list-range';
import { Wrapper } from '../models/wrapper';
import { UserProfileWrapper } from '../user-profiles/managers/user-profile-wrapper';
import { ConversationService } from './conversation.service';
import { ConversationWrapper } from './managers/conversation-wrapper';
import { SearchConversationsParams } from './models/search-conversations-params';

export class SearchConversationWithUserProfileResult implements Wrapper {
  constructor(
    public userProfile: UserProfileWrapper,
    public conversation: ConversationWrapper,
  ) {}

  getId(): string | number {
    return this.userProfile.getId();
  }

  destroy(): void {
    // This is intentionally left blank as userProfile and conversation are managed by their own managers
  }

  subscribe(disposableDataSource: DisposableDataSource): void {
    this.userProfile.subscribe(disposableDataSource);
    this.conversation.subscribe(disposableDataSource);
  }

  unsubscribe(disposableDataSource: DisposableDataSource): void {
    this.userProfile.unsubscribe(disposableDataSource);
    this.conversation.unsubscribe(disposableDataSource);
  }

  observed() {
    return this.userProfile.observed() || this.conversation.observed();
  }
}

export class SearchConversationWithUserProfileDataSource extends ArrayPagedDataSource<SearchConversationWithUserProfileResult> {
  private readonly conversationService: ConversationService;

  private readonly pageSize = 20;
  private readonly fetchedPageIdxs = new Set<number>();

  private readonly totalNumberOfItems$$ = new ReplaySubject<number>(1);

  private hasSetup = false;

  public constructor(container: interfaces.Container) {
    super();

    this.conversationService =
      container.get<ConversationService>(ConversationService);
  }

  public getTotalNumberOfItems$(): Observable<number> {
    return this.totalNumberOfItems$$.pipe(takeUntil(this.getDisconnect$()));
  }

  public setupAndGet$(
    searchConversationsParams: SearchConversationsParams,
    listRange$: Observable<DataSourceListRange>,
  ): Observable<SearchConversationWithUserProfileResult[]> {
    listRange$
      .pipe(
        distinctUntilChanged((a, b) => {
          return a.start == b.start && a.end == b.end;
        }),
        takeUntil(this.getComplete$()),
        takeUntil(this.getDisconnect$()),
      )
      .subscribe((range) => {
        const endPage = this.getPageForIndex(range.end);
        this.fetchPage(endPage + 1, searchConversationsParams);
      });

    if (this.hasSetup) {
      return this.getCachedItems$();
    }

    this.hasSetup = true;

    // Yields the initial empty array
    this.yieldSortedItems(true);

    this.setup(searchConversationsParams);

    return this.getCachedItems$();
  }

  private setup(searchConversationsParams: SearchConversationsParams): void {
    this.setupSortFunc(this.sortDescFunc);

    this.fetchPage(0, searchConversationsParams);
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  private fetchPage(
    page: number,
    searchConversationsParams: SearchConversationsParams,
  ): void {
    if (this.fetchedPageIdxs.has(page)) {
      return;
    }
    this.fetchedPageIdxs.add(page);

    const observable$ =
      this.conversationService.searchUserProfilesWithConversations$(
        page * this.pageSize,
        this.pageSize,
        searchConversationsParams,
        searchConversationsParams.searchKeyword,
      );

    // Update isLoading to true before starting to fetch data
    this.setIsFetchingNextPage(true);

    observable$
      .pipe(
        takeUntil(this.getComplete$()),
        takeUntil(this.getDisconnect$()),
        RxjsUtils.getRetryAPIRequest(),
      )
      .subscribe({
        next: (tuple) => {
          const totalNumberOfUserProfiles = tuple.totalNumberOfUserProfiles;
          const searchResults = tuple.searchResults;

          this.totalNumberOfItems$$.next(totalNumberOfUserProfiles);

          if (searchResults && searchResults.length > 0) {
            if (searchResults.length < this.pageSize) {
              this.complete();
            }

            this.addItems(
              searchResults.map((sr) => {
                return new SearchConversationWithUserProfileResult(
                  sr.userProfile,
                  sr.conversation,
                );
              }),
            );
          } else {
            this.yieldSortedItems();
          }
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
          this.setIsFetchingNextPage(false);
        },
      });
  }

  private sortDescFunc = (
    a: SearchConversationWithUserProfileResult,
    b: SearchConversationWithUserProfileResult,
  ) => {
    return (
      parseISO(b.conversation.getLastMessageCreatedAt()).getTime() -
      parseISO(a.conversation.getLastMessageCreatedAt()).getTime()
    );
  };
}
