import {
  AnalyticsApi,
  ShopifyApi,
  TravisBackendAnalyticsDomainModelsSegment,
  TravisBackendCompanyDomainModelsCondition,
  TravisBackendDataBackgroundTaskBackgroundTaskViewModel,
} from '@sleekflow/sleekflow-core-typescript-rxjs-apis';
import { Dayjs } from 'dayjs';
import { inject, injectable } from 'inversify';
import { map, Observable, shareReplay, take } from 'rxjs';

@injectable()
export class AnalyticsService {
  constructor(
    @inject(AnalyticsApi)
    private analyticsApi: AnalyticsApi,
    @inject(ShopifyApi)
    private shopifyApi: ShopifyApi,
  ) {}

  private conversationAnalyticsData$?: Observable<ConversationAnalyticsDto> =
    undefined;

  public getConversationsAnalytics$(
    timeframe: Dayjs[],
    conditions: TravisBackendCompanyDomainModelsCondition[],
  ): Observable<ConversationAnalyticsDto> {
    this.conversationAnalyticsData$ = this.analyticsApi
      .companyAnalyticsDataPost({
        from: timeframe[0].format('YYYY-MM-DD'),
        to: timeframe[1].format('YYYY-MM-DD'),
        travisBackendCompanyDomainModelsCondition: conditions,
      })
      .pipe(
        take(1),
        map((data) => {
          return data as unknown as ConversationAnalyticsDto;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: false,
        }),
      );
    return this.conversationAnalyticsData$;
  }

  private previousConversationAnalyticsData$?: Observable<ConversationAnalyticsDto> =
    undefined;

  public getPreviousConversationsAnalytics$(
    timeframe: Dayjs[],
    conditions: TravisBackendCompanyDomainModelsCondition[],
  ): Observable<ConversationAnalyticsDto> {
    this.previousConversationAnalyticsData$ = this.analyticsApi
      .companyAnalyticsDataPost({
        from: timeframe[0].format('YYYY-MM-DD'),
        to: timeframe[1].format('YYYY-MM-DD'),
        travisBackendCompanyDomainModelsCondition: conditions,
      })
      .pipe(
        take(1),
        map((data) => {
          return data as unknown as ConversationAnalyticsDto;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: false,
        }),
      );
    return this.previousConversationAnalyticsData$;
  }

  private shopifyOrderStatisticsData$?: Observable<ShopifyOrderStatisticsDto> =
    undefined;

  public getShopifyOrderStatistics$(
    timeframe: Dayjs[],
  ): Observable<ShopifyOrderStatisticsDto> {
    this.shopifyOrderStatisticsData$ = this.shopifyApi
      .companyShopifyOrderStatisticsGet({
        from: timeframe[0].format('YYYY-MM-DD'),
        to: timeframe[1].format('YYYY-MM-DD'),
      })
      .pipe(
        take(1),
        map((data) => {
          return data as unknown as ShopifyOrderStatisticsDto;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: false,
        }),
      );
    return this.shopifyOrderStatisticsData$;
  }

  private shopifyOrderStaffStatisticsData$?: Observable<ShopifyOrderStaffStatisticsDto> =
    undefined;

  public getShopifyOrderStaffStatistics$(
    timeframe: Dayjs[],
    limit?: number,
    offset?: number,
    sortBy?: string,
    sortOrder?: string,
    teamId?: number,
  ): Observable<ShopifyOrderStaffStatisticsDto> {
    this.shopifyOrderStaffStatisticsData$ = this.shopifyApi
      .companyShopifyOrderStaffStatisticsGet({
        from: timeframe[0].format('YYYY-MM-DD'),
        to: timeframe[1].format('YYYY-MM-DD'),
        limit: limit ?? 15,
        offset: offset ?? 0,
        sortBy:
          sortBy === undefined
            ? undefined
            : sortBy === 'linkSharedCount'
            ? 0
            : sortBy === 'paymentLinkSharedCount'
            ? 1
            : 2,
        sortOrder: sortOrder === 'false' ? undefined : sortOrder,
        teamId: teamId,
      })
      .pipe(
        take(1),
        map((data) => {
          return data as unknown as ShopifyOrderStaffStatisticsDto;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: false,
        }),
      );
    return this.shopifyOrderStaffStatisticsData$;
  }

  private exportAnalyticsData$?: Observable<TravisBackendDataBackgroundTaskBackgroundTaskViewModel> =
    undefined;

  public postExportAnalyticsData$(
    timeframe: Dayjs[],
  ): Observable<TravisBackendDataBackgroundTaskBackgroundTaskViewModel> {
    console.log('timeframe', timeframe);
    this.exportAnalyticsData$ = this.analyticsApi
      .companyAnalyticsExportBackgroundPost({
        from: timeframe[0].format('YYYY-MM-DD'),
        to: timeframe[1].format('YYYY-MM-DD'),
      })
      .pipe(
        take(1),
        map((data) => {
          return data as unknown as TravisBackendDataBackgroundTaskBackgroundTaskViewModel;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: false,
        }),
      );
    return this.exportAnalyticsData$;
  }

  private segmentData$?: Observable<Segment> = undefined;

  public getSegmentData$(): Observable<Segment> {
    this.segmentData$ = this.analyticsApi.companyAnalyticsSegmentGet().pipe(
      map((data) => {
        return data as unknown as SegmentList[];
      }),
      take(1),
      shareReplay({
        bufferSize: 1,
        refCount: false,
      }),
    );
    return this.segmentData$;
  }

  public createSegment$(
    data: TravisBackendAnalyticsDomainModelsSegment,
  ): Observable<void> {
    return this.analyticsApi
      .companyAnalyticsSegmentCreatePost({
        travisBackendAnalyticsDomainModelsSegment: data,
      })
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }

  public deleteSegment$(id: number): Observable<void> {
    return this.analyticsApi
      .companyAnalyticsSegmentSegmentIdDelete({
        segmentId: id,
      })
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }

  public updateSegment$(
    id: number,
    data: TravisBackendAnalyticsDomainModelsSegment,
  ): Observable<void> {
    return this.analyticsApi
      .companyAnalyticsSegmentUpdateSegmentIdPut({
        segmentId: id,
        travisBackendAnalyticsDomainModelsSegment: data,
      })
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
}

export interface ConversationAnalyticsDto {
  companyId: string;
  conditions: any[];
  startDate: string;
  endDate: string;
  summary: Summary;
  dailyLogs: DailyLog[];
}

export interface Summary {
  responseTimeForFirstMessages: string;
  responseTimeForAllMessages: string;
  numberOfContacts: number;
  numberOfContactsAverage: number;
  numberOfAllConversations: number;
  numberOfAllConversationsAverage: number;
  numberOfActiveConversations: number;
  numberOfActiveConversationsAverage: number;
  numberOfNewEnquires: number;
  numberOfNewEnquiresAverage: number;
  numberOfMessagesSent: number;
  numberOfMessagesSentAverage: number;
  numberOfMessageReceived: number;
  numberOfMessageReceivedAverage: number;
  numberOfAutomatedMessages: number;
  numberOfBroadcastSent: number;
  numberOfBroadcastBounced: number;
  numberOfBroadcastDelivered: number;
  numberOfBroadcastRead: number;
  numberOfBroadcastReplied: number;
  activeAgents: number;
  numberOfUniqueActiveConversations: number;
  numberOfUniqueActiveConversationAverage: number;
  numberOfUniqueConversations: number;
  numberOfUniqueConversationAverage: number;
}

export interface DailyLog {
  dateTime: string;
  responseTimeForFirstMessages: string;
  responseTimeForAllMessages: string;
  numberOfContacts: number;
  numberOfContactsAverage: number;
  numberOfAllConversations: number;
  numberOfAllConversationsAverage: number;
  numberOfActiveConversations: number;
  numberOfActiveConversationsAverage: number;
  numberOfNewEnquires: number;
  numberOfNewEnquiresAverage: number;
  numberOfMessagesSent: number;
  numberOfMessagesSentAverage: number;
  numberOfMessageReceived: number;
  numberOfMessageReceivedAverage: number;
  numberOfAutomatedMessages: number;
  numberOfBroadcastSent: number;
  numberOfBroadcastBounced: number;
  numberOfBroadcastDelivered: number;
  numberOfBroadcastRead: number;
  numberOfBroadcastReplied: number;
  activeAgents: number;
  numberOfUniqueActiveConversations: number;
  numberOfUniqueActiveConversationAverage: number;
  numberOfUniqueConversations: number;
  numberOfUniqueConversationAverage: number;
}

export interface ShopifyOrderStatisticsDto {
  targetPeriod: TargetPeriod;
  previousPeriod: PreviousPeriod;
}

export interface TargetPeriod {
  startDate: string;
  endDate: string;
  companyPerformance: CompanyPerformance[];
  companyInfluencedSalesPerformance: any[]; // unknown because of the lack of information
  companyConversionPerformance: CompanyConversionPerformance[];
  teamConversionPerformance: CompanyConversionPerformance[]; // unknown because of the lack of information
}

export interface CompanyPerformance {
  type: string;
  from: string;
  to: string;
  status: string;
  totalPrice: number;
  totalCount: number;
}

export interface CompanyConversionPerformance {
  type: string;
  from: string;
  to: string;
  status: string;
  totalPrice: number;
  totalCount: number;
}

export interface PreviousPeriod {
  startDate: string;
  endDate: string;
  companyPerformance: any[]; // unknown because of the lack of information
  companyInfluencedSalesPerformance: any[]; // unknown because of the lack of information
  companyConversionPerformance: any[]; // unknown because of the lack of information
  teamConversionPerformance: any[]; // unknown because of the lack of information
}

export interface ShopifyOrderStaffStatisticsDto {
  data: Daum[];
  count: number;
}

export interface Daum {
  staff: Staff;
  linkSharedCount: number;
  linkSharedClicks: number;
  paymentLinkSharedCount: number;
  paymentLinkSharedClicks: number;
  paymentLinkSharedPaid: number;
  paymentLinkConvertedAmount: number;
}

export interface Staff {
  userInfo: UserInfo;
  staffId: number;
  roleType: string;
  name: string;
  timeZoneInfoId: string;
  position: string;
  profilePictureURL?: string;
  profilePicture?: ProfilePicture;
  status: string;
  isAcceptedInvitation: boolean;
  notificationSetting: NotificationSetting;
  isShowName: boolean;
  isNewlyRegistered: boolean;
  qrCodeIdentity: string;
  locale?: string;
  qrCodeChannel?: QrCodeChannel;
}

export interface UserInfo {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  userName: string;
  email: string;
  userRole?: string;
  phoneNumber: string;
  emailConfirmed: boolean;
  createdAt: string;
}

export interface ProfilePicture {
  id: number;
  profilePictureId: string;
  companyId: string;
  blobContainer: string;
  filename: string;
  url: string;
  mimeType: string;
}

export interface NotificationSetting {
  id: number;
  emailNotificationNewMessages: boolean;
  emailNotificationConversationUpdates: boolean;
}

export interface QrCodeChannel {
  channel: string;
  ids: string[];
}

export type Segment = SegmentList[];

export interface SegmentList {
  id: number;
  companyId: string;
  name: string;
  conditions: Condition[];
  createdAt: string;
  updatedAt: string;
  status: string;
}

export interface Condition {
  fieldName: string;
  conditionOperator: string;
  values: string[];
  nextOperator: string;
  containHashTag?: string;
}
